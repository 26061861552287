
import axios from "@/plugins/axios.js"

const actions  = {
  fetchNotifications({ commit, state}, payload = {}) {
    return new Promise((resolve, reject) => {
      axios.get(`/activities?${new URLSearchParams(payload).toString()}`)
        .then((response) => {
          if(response.data.success){
            commit('SET_NOTIFICATIONS', response.data.data)
          }
          resolve(response)
        }).catch((error) => { reject(error) })
    })
  },
  markNotifAsRead({ commit }, notifId) {
    return new Promise((resolve, reject) => {
      axios.get(`/activities/${notifId}/markasread`)
        .then((response) => {
          if(response.data.success){
            commit('MARK_AS_READ', notifId)
          }
          resolve(response)
        }).catch((error) => { reject(error) })
    })
  }
}

const getters  = {
  // getItem: state => (productId) => state.products.find((product) => product.id == productId),
}



const mutations  = {
  SET_NOTIFICATIONS(state, data) {
    state.pageData = data
  },
  ADD_NOTIFICATION(state, notification) {
      state.pageData.data.unshift(notification)
  },
  MARK_AS_READ(state, notifId){
  	state.pageData.data.map((item)=>{
  		if(item.id == notifId){
  			return item.is_read = 1
  		}
  	})
  }

}


const state  = {
  pageData: {
    data: []
  },
}

export default {
  isRegistered: true,
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}

