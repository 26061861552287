

import axios from "@/plugins/axios.js"

const actions = {
  fetchBulletins({ commit, state }, force = false) {
    if(!force && state.list.length) return
    return new Promise((resolve, reject) => {
      axios.get("/bulletins")
        .then((response) => {
          if(response.data.success){
            commit('SET_ITEMS', response.data.data)
          }
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
}

const getters = {
}

const mutations  = {
  ADD_ITEM(state, item) {
    state.list.unshift(item)
  },
  SET_ITEMS(state, data) {
    state.list = data
  },
  UPDATE_ITEM(state, item) {
    const dataIndex = state.list.findIndex((o) => o.id == item.id);
    Object.assign(state.list[dataIndex], item);
  },
  DELETE_ITEM(state, itemId) {
    const ItemIndex = state.list.findIndex((item) => item.id == itemId)
    state.list.splice(ItemIndex, 1)
  },
}


const state = {
  list: [],
}

export default {
  isRegistered: true,
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}

